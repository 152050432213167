<template>
  <div class="content profile-content">
    <ProfileNavbar :title="$t('profile.results.title')"></ProfileNavbar>
    <div class="mob-menu-title">
      <!--            <router-link tag="a" to="/profile"  class="mob-menu-back">-->
      <!--                <img src="images/profile/arrow-back.svg">-->
      <!--            </router-link>-->
      {{ $t('profile.results.title') }}
    </div>
    <div class="clearfix bg-blue">
      <div class="">
        <el-radio-group v-if="resultFile" style="margin:0 auto; padding-left: 16px" class="w-100" v-model="tab">
              <span @click="tab=1"><el-radio-button
                  label="1">{{$t('profile.results.chapter-1')}}</el-radio-button></span>
          <span @click="tab=2"><el-radio-button
              label="2">{{$t('test-results.kindergartenTest')}}</el-radio-button></span>
        </el-radio-group>
        <div class="my-results-table-wrapper">
          <!-- <div class="mx-auto pt-4 pl-2 pr-2"> -->
          <div v-if="tab==1" class="my-results-table">

            <div class="results-table table-responsive-md">
              <el-table
                  class="mb-4"
                  stripe
                  v-loading="loading"
                  :data="results"
                  style="width: 100%">
                <el-table-column
                    width="250"
                    prop="quiz_title"
                    :label="$t('profile.results.test')">
                </el-table-column>
                <el-table-column
                    prop="result"
                    class="text-center"
                    :label="$t('profile.results.score')">
                </el-table-column>
                <el-table-column
                    prop="date"
                    class="text-center"
                    :label="$t('profile.results.date')">
                </el-table-column>

                <el-table-column
                    :label="$t('my-consultations.actions')">
                  <template slot-scope="scope">
                    <div class="result-recomendations">
                      <button v-if="scope.row.quiz_type_id === 4"  class="btn btn-blue" @click="showResults(scope.row.user_quiz_id, scope.row.user_quiz_token,scope.row.quiz_type_id, scope.row.start_age, scope.row.end_age )">
                        {{ $t('profile.results.view-btn3') }}
                      </button>
                      <!--                      <button v-if="scope.row.quiz_type_id != 4" @click="showMore(scope.row.user_quiz_id,null,scope.row.start_age,scope.row.end_age)" class="btn btn-blue">-->
                      <!--                        {{ $t('profile.results.view-btn1') }}-->
                      <!--                      </button>-->
                      <button v-if="scope.row.quiz_type_id !== 4 && scope.row.quiz_type_id !== 0" @click="showResults(scope.row.user_quiz_id, scope.row.user_quiz_token, scope.row.quiz_type_id, scope.row.start_age, scope.row.end_age )" class="btn btn-blue">
                        {{ $t('profile.results.view-btn1') }}
                      </button>
                      <button v-if="scope.row.quiz_type_id === 0" @click="showResults(scope.row.user_quiz_id, scope.row.user_quiz_token, scope.row.quiz_type_id, scope.row.start_age, scope.row.end_age )" class="btn btn-blue">
                        {{ $t('profile.results.view-btn4') }}
                      </button>
                      <button style="margin-top: 1rem" v-if="scope.row.second_step" @click="showMore(scope.row.user_quiz_id,scope.row.second_step)" class="btn btn-blue ">
                        {{ $t('profile.results.view-btn2') }}
                      </button>
                      <a  v-if="scope.row.recommendation.file" :href="scope.row.recommendation.file" download
                          style="margin-top: 1rem;display: block" class="btn btn-blue">
                        {{ $t('test-results.download-file') }}
                      </a>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                  @current-change="handleCurrentChange"
                  layout="prev, pager, next"
                  :total="total">
              </el-pagination>
            </div>
          </div>

          <div v-if="tab==2" class="my-results-table">

            <div class="results-table table-responsive-md">
              <el-table
                  :data="[0]"
                  class="mb-4"
                  stripe
                  style="width: 100%">
                <el-table-column
                    width="250"
                    :label="$t('profile.results.test')">
                  <template slot-scope="scope">
                    <span>
                    {{nameDiagnostic}}
                      </span>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="date"
                    class="text-center"
                    :label="$t('profile.children.comment')">
                  <template slot-scope="scope">
                    <span>
                    {{commentDiagnostic}}
                      </span>
                  </template>
                </el-table-column>
                <el-table-column
                    class="text-center"
                    :label="$t('profile.results.date')">
                  <template slot-scope="scope">
                    <span>
                    {{ diagnosticDate.substr(0,10).split('-').join('.')}}
                      </span>
                  </template>
                </el-table-column>

                <el-table-column
                    :label="$t('my-consultations.actions')">
                  <template slot-scope="scope">
                    <div class="result-recomendations">
                      <a v-if="resultFile" :href="apiRoot+'/'+ resultFile" download style="text-decoration: none!important; color:white!important;margin-bottom: 1rem"
                         class="btn btn-blue">

                        {{ $t('test-results.download-file-diagnostic') }}
                      </a>
                    </div>
                  </template>
                </el-table-column>
              </el-table>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>
<script>
import Vue from 'vue';
import ProfileNavbar from "@/components/ProfileNavbar";

export default {
  name: 'Results',
  components: {
    ProfileNavbar,
  },
  data() {
    return {
      tab:1,
      diagnosticDate:'',
      commentDiagnostic:'',
      nameDiagnostic:'',
      resultFile:'',
      apiRoot: window.API_ROOT,
      specialTest: false,
      loading: false,
      total: 0,
      results: [],
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    dateReplace(){
      for (var i=0; i<this.results.length; i++){
        this.results[i].date=this.results[i].date.replace('-', '.')
        this.results[i].date=this.results[i].date.replace('-', '.')
      }
    },
    handleCurrentChange(val) {
      this.loading = true;
      this.$http.get(`${window.API_ROOT}/api/quizzes/results?page=${val}`)
          .then((res) => {
            this.results = res.body.items
            this.dateReplace()
            this.loading = false;
          }).catch((e) => {
        this.loading = false;
      })
    },
    getInfo() {
      this.loading = true;
      this.$http.get(window.API_ROOT + '/api/quizzes/results')
          .then((res) => {
            this.diagnosticDate=res.body.created
            this.commentDiagnostic=res.body.diagnostic_file_comment
            this.nameDiagnostic=res.body.quiz_title
            this.resultFile=res.body.diagnostic_file
            this.results = res.body.items

            console.log('this.results', this.results)


            this.dateReplace()
            this.total = res.body.total;
            this.specialTest = res.body.special_is_available;
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
          });
    },
    showMore(id,step,start_age,end_age) {
      if(step==null){
        if(start_age<=7 && end_age>= 9){
          var url = this.$router.resolve({name: 'test-results7-9', query: {'id': id}})

        }
        else {
          var url = this.$router.resolve({name: 'test-results', query: {'id': id}})

        }
      }
      else {
        var url = this.$router.resolve({name: 'test-results-second', query: {'id': id}})
      }
      window.open(url.href);
    },
    showResults(user_quiz_id, user_quiz_token, quiz_type_id, start_age, end_age) {
      if(start_age === null && end_age === null && quiz_type_id === 4) {
        this.$router.push({name: 'test-results-new', params: {id: user_quiz_id, token: user_quiz_token}});
      }else if((start_age === 12 || start_age === "12") && (end_age === 14 || end_age === "14")) {
        this.$router.push({name: 'test-results-new', params: {id: user_quiz_id, token: user_quiz_token}});
      }else if((start_age === 15 || start_age === "15") && (end_age === 17 || end_age === "17")) {
        this.$router.push({name: 'test-results-15-17', params: {id: user_quiz_id, token: user_quiz_token}});
      }else if(start_age === 0 && end_age === 0 && quiz_type_id === 0) {
        this.$router.push({name: 'profqabilet', params: {id: user_quiz_id, token: user_quiz_token}});
      }else{
        this.$router.push('/test-results?id=' + user_quiz_id + '&token=' + user_quiz_token);
      }
    },
  }
}
</script>
<style>
.el-table__body {
  width: 100% !important;
  table-layout: auto;
}

.el-table th, .el-table td {
  text-align: center;
}

.el-table th:last-child {
  text-align: right;
}

.btn.btn-blue {
  line-height: 32px;
}

.result-recomendations {
  text-align: right;
}
.result-recomendations > a {
  margin-top: 8px;
}
.result-recomendations > button {
  min-width: 125px;
}
.pt-4, .py-4 {
  padding-top: 2.5rem !important;
}
.el-pagination .btn-next {
  width: auto;
}
.result-recomendations {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.content .my-results-table {
  max-width: 1320px;
  margin: 0;
}
.el-pagination {
  text-align: center;
}
@media screen and (max-width: 1300px) {
  .result-recomendations > .btn-blue {
    font-size: 14px;
  }
}
@media screen and (max-width: 991px) {
  .el-pagination {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .el-table__body > colgroup {
    display: none;
  }


  .result-recomendations > button,
  .result-recomendations > a {
    min-width: 125px;
  }

  .btn.btn-blue {
    margin: 15px auto;
  }

  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background: none;
  }

  .my-results-table tbody tr {
    width: 100%;
  }
}
.el-pagination .btn-next {
  width: auto;
}
.result-recomendations {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.content .my-results-table {
  border: 1px solid transparent;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
}
@media screen and (max-width: 1300px) {
  .result-recomendations > .btn-blue {
    font-size: 14px;
  }
}
@media screen and (max-width: 991px) {
  .el-pagination {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .el-table__body > colgroup {
    display: none;
  }


  .result-recomendations > button,
  .result-recomendations > a {
    min-width: 125px;
  }

  .btn.btn-blue {
    margin: 15px auto;
  }

  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background: none;
  }

  .my-results-table tbody tr {
    width: 100%;
  }
}
</style>
